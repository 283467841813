<template>
  <div
    class="shadow-md rounded border border-etBlack-30 bg-white w-full p-6 h-max"
  >
    <div class="flex gap-4 border-b border-etBlack-30">
      <div
        v-if="!isSupplier"
        class="py-[3px] rounded-sm cursor-pointer [&>*]:hover:bg-etBlue-10 [&>*]:hover:!text-etBlue-primary"
        :class="{
          'border-b-2 border-etBlue-primary [&>*]:text-etBlue-primary [&>*]:bg-etBlue-10':
            basicSettings
        }"
        data-cy="toggle_basic_settings"
        @click="handleBasicSettings"
      >
        <TextSmall class="py-[8px] px-[10px]">
          {{ $t('shared.caas.sidebar.basic') }}
        </TextSmall>
      </div>
      <div
        class="py-[3px] rounded-sm cursor-pointer [&>*]:hover:bg-etBlue-10 [&>*]:hover:!text-etBlue-primary"
        :class="{
          'border-b-2 border-etBlue-primary [&>*]:text-etBlue-primary [&>*]:bg-etBlue-10':
            advancedSettings
        }"
        data-cy="toggle_advanced_settings"
        @click="handleAdvancedSettings"
      >
        <TextSmall class="py-[8px] px-[10px]">
          {{
            isSupplier
              ? $t('shared.caas.sidebar.tools')
              : $t('shared.caas.sidebar.advanced')
          }}
        </TextSmall>
      </div>
      <Tooltip
        v-if="
          route.name === 'contentGenerationV2' &&
          currentDocument &&
          showSaveButton
        "
        class="py-[3px] cursor-pointer ml-auto"
        :label="'Save settings as a template'"
        position="top"
        :black-theme="true"
        data-cy="toggle_save_settings"
        @click="$emit('saveCurrentSettings')"
      >
        <font-awesome-icon
          class="py-[8px] px-[10px]"
          color="#454a5b"
          size="lg"
          icon="fa-regular fa-floppy-disk"
        />
      </Tooltip>
      <div
        v-if="isSupplier && props.customerInstructions"
        class="py-[3px] hei rounded-sm cursor-pointer [&>*]:hover:bg-etBlue-10 [&>*]:hover:!text-etBlue-primary"
        :class="{
          'border-b-2 border-etBlue-primary [&>*]:text-etBlue-primary [&>*]:bg-etBlue-10':
            instructionSettings
        }"
        data-cy="toggle_instructions"
        @click="handleInstructionsSettings"
      >
        <TextSmall class="py-[8px] px-[10px]">
          {{ $t('shared.caas.sidebar.instructions') }}
        </TextSmall>
      </div>
    </div>
    <div v-if="basicSettings || advancedSettings">
      <div class="mt-[25px] mb-[15px] flex flex-col gap-2">
        <div class="flex items-center justify-between sliderLabel">
          <TextSmall>
            {{ $t('shared.caas.sidebar.creativity') }}
          </TextSmall>
          <InputField
            id="temperature_input"
            class="max-w-[100px]"
            input-appearance-none
            :value="setTemperature"
            :min="0"
            :max="1"
            :step="0.01"
            :disabled="disabled"
            centered
            type="number"
            @inputChange="handleTemperature"
          />
        </div>
        <RangeSlider
          id="temperature_slider"
          :update-key="1"
          :min="0"
          :max="1"
          :step="0.01"
          :disabled="disabled"
          :value="setTemperature"
          @updateValue="rangeTemperature"
        />
        <div class="flex items-center justify-between">
          <TextTiny>{{ $t('shared.caas.sidebar.low') }}</TextTiny>
          <TextTiny>{{ $t('shared.caas.sidebar.high') }}</TextTiny>
        </div>
      </div>
      <div class="mt-[25px] mb-[15px] flex flex-col gap-2">
        <div class="flex items-center justify-between sliderLabel">
          <TextSmall>{{ $t('shared.caas.sidebar.tokens') }}</TextSmall>
          <InputField
            id="tokens_input"
            class="max-w-[100px]"
            input-appearance-none
            :value="setToken"
            :min="0"
            :max="3000"
            type="number"
            centered
            :disabled="disabled"
            @inputChange="handleTokens"
          />
        </div>
        <RangeSlider
          :update-key="2"
          :min="0"
          :max="3000"
          :value="setToken"
          :disabled="disabled"
          @updateValue="rangeToken"
        />
      </div>
      <div class="mt-[25px] mb-[15px] flex flex-col gap-2">
        <div class="flex items-center justify-between">
          <TextSmall>
            {{ $t('shared.content_generation.content_type') }}
          </TextSmall>
        </div>
        <Tags
          v-if="loadedInitialValues"
          id="content_type"
          ref="contentTags"
          :settings="tagifySettings"
          :value="setContentType"
          :disabled="disabled"
          placeholder="Commercial, informational, long tail"
        />
      </div>
      <div
        v-if="advancedSettings"
        data-cy="presence_penalty"
        class="mt-[25px] mb-[15px] flex flex-col gap-2"
      >
        <div class="flex items-center justify-between sliderLabel">
          <TextSmall>{{ $t('shared.caas.sidebar.presence') }}</TextSmall>
          <InputField
            id="presence_input"
            class="max-w-[100px]"
            input-appearance-none
            :value="setPresencePenalty"
            :min="0"
            :max="2"
            :step="0.01"
            type="number"
            centered
            :disabled="disabled"
            @inputChange="handlePresence"
          />
        </div>
        <RangeSlider
          :update-key="3"
          :min="0"
          :max="2"
          :step="0.01"
          :disabled="disabled"
          :value="setPresencePenalty"
          @updateValue="rangePresence"
        />
        <div class="flex items-center justify-between">
          <TextSmall>{{ $t('shared.caas.sidebar.low') }}</TextSmall>
          <TextSmall>{{ $t('shared.caas.sidebar.high') }}</TextSmall>
        </div>
      </div>
      <div
        v-if="advancedSettings"
        data-cy="frequency_penalty"
        class="mt-[25px] mb-[15px] flex flex-col gap-2"
      >
        <div class="flex items-center justify-between sliderLabel">
          <TextSmall>{{ $t('shared.caas.sidebar.frequency') }}</TextSmall>
          <InputField
            id="frequency_input"
            class="max-w-[100px]"
            input-appearance-none
            :value="setFrequencyPenalty"
            :min="0"
            :max="2"
            :step="0.01"
            type="number"
            centered
            :disabled="disabled"
            @inputChange="handleFrequency"
          />
        </div>
        <RangeSlider
          :update-key="4"
          :min="0"
          :max="2"
          :step="0.01"
          :disabled="disabled"
          :value="setFrequencyPenalty"
          @updateValue="rangeFrequency"
        />
      </div>
      <div class="mt-[25px] mb-[15px] flex flex-col gap-2">
        <div class="flex items-center justify-between sliderLabel">
          <TextSmall>
            {{ $t('shared.content_generation.main_subject') }}
          </TextSmall>
          <SwitchButton
            data-cy="active_state_subject"
            v-model="activeStateSubject"
            :disabled="disabled"
            size="sm"
            @on="$emit('on', 'mainSubject')"
            @off="$emit('off', 'mainSubject')"
          />
        </div>
        <div v-if="activeStateSubject">
          <InputField
            v-if="!disabled"
            id="main_subject_input"
            :value="setMainSubject"
            :placeholder="isSupplier ? '' : 'Localisation'"
            type="string"
            :disabled="disabled"
            @inputChange="handleSubject"
          />
          <template v-else>
            <span>{{ setMainSubject }}</span>
          </template>
        </div>
      </div>
      <div class="mt-[25px] mb-[15px] flex flex-col gap-2">
        <div class="flex items-center justify-between sliderLabel">
          <TextSmall>
            {{ $t('shared.content_generation.tone_voice') }}
          </TextSmall>
          <SwitchButton
            data-cy="active_state_tone"
            v-model="activeStateTone"
            :disabled="disabled"
            size="sm"
            @on="$emit('on', 'tone_of_voice')"
            @off="$emit('off', 'tone_of_voice')"
          />
        </div>
        <div v-if="activeStateTone">
          <Tags
            v-if="loadedInitialValues"
            id="tone"
            ref="toneTags"
            :settings="tagifySettings"
            :value="setToneTags"
            :disabled="disabled"
            placeholder="Witty, serious"
          />
        </div>
      </div>
      <div class="mt-[25px] mb-[15px] flex flex-col gap-2">
        <div class="flex items-center justify-between sliderLabel">
          <TextSmall> {{ $t('shared.label.keywords') }} </TextSmall>
          <SwitchButton
            data-cy="active_state_keywords"
            v-model="activeStateKeywords"
            :disabled="disabled"
            size="sm"
            @on="$emit('on', 'keywords')"
            @off="$emit('off', 'keywords')"
          />
        </div>
        <div v-if="activeStateKeywords">
          <Tags
            v-if="loadedInitialValues"
            id="keywords"
            ref="keywordsTags"
            :settings="tagifySettings"
            :value="setKeywordsTags"
            :disabled="disabled"
            placeholder="fresh, young, vibrant"
          />
        </div>
      </div>
    </div>
    <div
      v-if="instructionSettings && props.customerInstructions"
      data-cy="customer_instructions"
      class="mt-4 roudned p-2 bg-etBlack-10"
      v-html="instructions"
    />
  </div>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import InputField from '@/components/shared/input/InputField'
import RangeSlider from '@/components/shared/RangeSlider'
import SwitchButton from '@/components/shared/switch/SwitchButton'
import Tags from '@/components/shared/tags/Tags'
import Tooltip from '@/components/shared/tooltip/Tooltip'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const $t = i18n.t

const emit = defineEmits(['settings', 'on', 'off', 'saveCurrentSettings'])

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  customerInstructions: {
    type: String,
    default: null
  },
  existingTemplate: {
    type: Object,
    default: null
  },
  newTemplate: {
    type: Boolean,
    default: false
  },
  showSaveButton: {
    type: Boolean,
    default: true
  },
  sidebarKey: Number
})

const setToken = ref(1000)
const setTemperature = ref(0.5)
const setPresencePenalty = ref(0)
const setFrequencyPenalty = ref(0)
const setMainSubject = ref('')
const setContentType = ref([])
const setContentBrief = ref('')
const setToneTags = ref([])
const setKeywordsTags = ref([])
const basicSettings = ref(true)
const advancedSettings = ref(false)
const instructionSettings = ref(false)
const activeStateSubject = ref(true)
const activeStateTone = ref(true)
const activeStateKeywords = ref(true)
const activeMaxCharacters = ref(false)
const activeTokens = ref(true)

const contentTags = ref(null)
const toneTags = ref(null)
const keywordsTags = ref(null)
const tagifySettings = ref({
  // This regex matches single words and sentences in any european language based on latin alfabet:
  pattern: /^(.+ ?|.+\s+.+|.+\s+.+.+\s+.+ ?)$/,
  addTagOnBlur: true,
  callbacks: {
    invalid: () => {
      error({
        message: $t('customer.toast.error.caas_invalid_key')
      })
    }
  }
})

const loadedInitialValues = ref(false)

defineExpose({
  toneTags,
  keywordsTags,
  contentTags,
  activeMaxCharacters,
  activeTokens
})

const currentDocument = computed(
  () => store.state.contentGeneration.currentDocument
)
const currentDocumentSettings = computed(() => {
  if (!store.state.contentGeneration.currentDocument) return null
  return store.state.contentGeneration.currentDocument.attributes.meta[0]
    .attributes
})

const appliedTemplate = computed(
  () => store.state.contentGeneration.appliedTemplate
)
const isSupplier = computed(() => {
  return $cookies.get('userType') === 'supplier'
})
const instructions = computed(() => {
  return props.customerInstructions.replace(/\n/g, '<br />')
})

onMounted(() => {
  setInitialValues()
})

const error = (payload) => store.dispatch('toast/error', payload)

function setInitialValues(appliedTemplate) {
  let initialSettings = {}
  if (appliedTemplate) {
    initialSettings = { ...appliedTemplate }
  } else {
    initialSettings = props.existingTemplate
      ? { ...props.existingTemplate }
      : { ...currentDocumentSettings.value }
  }
  if (!props.newTemplate || appliedTemplate) {
    const frequency_penalty =
      Number(initialSettings.frequency_penalty) < 0
        ? 0
        : Number(initialSettings.frequency_penalty)

    const presence_penalty = Number(initialSettings.presence_penalty)
    if (frequency_penalty > 0 || presence_penalty > 0 || isSupplier.value) {
      basicSettings.value = false
      advancedSettings.value = true
      instructionSettings.value = false
    }
    setToken.value = initialSettings.maximum_word_length
    setTemperature.value = Number(initialSettings.creativity)
    setPresencePenalty.value = presence_penalty
    setFrequencyPenalty.value = frequency_penalty
    setMainSubject.value = initialSettings.subject
    setContentBrief.value = initialSettings.content_brief
    setContentType.value = initialSettings.content_type
    setToneTags.value = initialSettings.tone_of_voice
    setKeywordsTags.value = initialSettings.keywords
  } else {
    setToken.value = 1000
    setTemperature.value = 0.5
    setPresencePenalty.value = 0
    setFrequencyPenalty.value = 0
    setMainSubject.value = ''
    setContentType.value = []
    setContentBrief.value = ''
    setToneTags.value = []
    setKeywordsTags.value = []
    basicSettings.value = true
    advancedSettings.value = false
    instructionSettings.value = false
    activeStateSubject.value = true
    activeStateTone.value = true
    activeStateKeywords.value = true
    activeMaxCharacters.value = false
    activeTokens.value = true
  }
  loadedInitialValues.value = true
}

function handleBasicSettings() {
  basicSettings.value = true
  advancedSettings.value = false
  emit('settings', ['type', 'basic'])
}
function handleAdvancedSettings() {
  advancedSettings.value = true
  basicSettings.value = false
  instructionSettings.value = false
  emit('settings', ['type', 'advanced'])
}
function handleInstructionsSettings() {
  advancedSettings.value = false
  instructionSettings.value = true
}
function rangeToken(val) {
  setToken.value = val
  emit('settings', ['maximum_word_length', setToken])
}
function handleTokens(val) {
  setToken.value = val.value
  if (val.value > 3000) {
    setToken.value = 3000
  }
  emit('settings', ['maximum_word_length', setToken])
}
function rangeTemperature(val) {
  setTemperature.value = val
  emit('settings', ['creativity', setTemperature])
}
function handleTemperature(val) {
  setTemperature.value = val.value
  emit('settings', ['creativity', setTemperature])
}
function rangePresence(val) {
  setPresencePenalty.value = val
  emit('settings', ['presence_penalty', setPresencePenalty])
}
function handlePresence(val) {
  setPresencePenalty.value = val.value
  emit('settings', ['presence_penalty', setPresencePenalty])
}
function rangeFrequency(val) {
  setFrequencyPenalty.value = val
  emit('settings', ['frequency_penalty', setFrequencyPenalty])
}
function handleFrequency(val) {
  setFrequencyPenalty.value = val.value
  emit('settings', ['frequency_penalty', setFrequencyPenalty])
}
function handleSubject(val) {
  setMainSubject.value = val.value
  emit('settings', ['subject', setMainSubject])
}

watch(
  () => appliedTemplate.value,
  (template) => {
    loadedInitialValues.value = false
    setInitialValues(template.attributes)
  },
  { deep: true }
)

watch(
  () => currentDocumentSettings.value,
  (settings) => {
    loadedInitialValues.value = false
    setInitialValues()
  },
  { deep: true }
)

watch(
  () => props.newTemplate,
  (temp) => {
    loadedInitialValues.value = false
    setInitialValues()
  }
)

watch(
  () => props.existingTemplate,
  (temp) => {
    loadedInitialValues.value = false
    setInitialValues()
  }
)

watch(
  () => props.sidebarKey,
  (newKey) => {
    loadedInitialValues.value = false
    setInitialValues()
  }
)
</script>
